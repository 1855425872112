.title {
  text-decoration: underline;
  text-align: left;
  padding-left: 20px;
  margin-bottom: 0;
  font-size: calc(12px + 3vmin);
}

.CTA {
  font-size: calc(10px + 3vmin);
}

.info {
  text-align: center;
  padding-left: 15%;
  padding-right: 0%;
  padding-bottom: 0px;
  padding-top: 0px;
}

.positions {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.stance {
  /* background-color: #888; */
  text-align: left;
  min-width: 550px;
  align-self: flex-start;
  padding-left: 5%;
  padding-right: 5%;
}

.About {
  padding-left: 10px;
}
